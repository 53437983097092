<template>
	<Page>
		<Body>
			<Section size="xlarge" image="theme-dl/intro.jpg">
				<Heading size="3" uppercase>Overview</Heading>
				<Heading size="1">Dallas Art Academy</Heading>
				<Grid>
					<GridContainer size="12 6@lg">						
						<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus laoreet quis mi eu porta. Integer imperdiet, erat non sagittis ultricies, non imperdiet arcu diam et orci.</Paragraph>
					</GridContainer>
				</Grid>
			</Section>
			<Section size="xsmall" color="primary" full>
				<Grid>
					<GridContainer size="3">
						<Images width="100%" height="100%" image="theme-dl/neon.jpg" />
					</GridContainer>
					<GridContainer size="6">
						<Stack width="100%" height="100%" align="center">
							<Card size="xlarge" color="primary">
								<CardSection>
									<Heading size="4" whiteout uppercase>About Us</Heading>
									<Heading size="2" whiteout>Immerse yourself in our creative culture</Heading>
									<Paragraph whiteout>Donec vitae arcu in lacus hendrerit consectetur a in mauris. Duis rutrum mattis nulla, at malesuada felis imperdiet vel. Sed gravida eros et mauris placerat ullamcorper. Vestibulum eget volutpat dui. Aliquam hendrerit finibus diam, eu facilisis eros porta vel. Etiam magna neque, sagittis sit amet arcu a, laoreet tempus tortor.</Paragraph>
									<Button label="read more" color="secondary" />
								</CardSection>
							</Card>
						</Stack>
					</GridContainer>
					<GridContainer size="3">
						<Images width="100%" height="100%" image="theme-dl/man.jpg" />
					</GridContainer>
				</Grid>
			</Section>
			<Section size="xsmall" color="primary" full>
				<Grid>
					<GridContainer size="6">
						<Card size="xlarge" color="secondary">
							<CardSection>
								<Heading size="4" whiteout uppercase>Core Disciplines</Heading>
								<Heading size="2" whiteout>Architecture</Heading>								
								<Paragraph whiteout>Phasellus eget urna sed est cursus viverra ac a nibh. Maecenas porta eget nisi accumsan viverra. Maecenas et posuere quam, a semper velit.</Paragraph>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="6">
						<Card size="xlarge" image="theme-dl/cranes.jpg">
							<CardSection>
								<Heading size="4" whiteout uppercase>Core Disciplines</Heading>
								<Heading size="2" whiteout>Design</Heading>
								<Paragraph whiteout>Donec imperdiet massa convallis eleifend tempor. Quisque sollicitudin, sapien nec ultricies vestibulum, leo augue egestas tellus non auctor.</Paragraph>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="6">
						<Card size="xlarge" image="theme-dl/putty.jpg">
							<CardSection>
								<Heading size="4" whiteout uppercase>Core Disciplines</Heading>
								<Heading size="2" whiteout>Fine Arts</Heading>
								<Paragraph whiteout>Integer consequat ligula a nulla congue, sit amet luctus enim facilisis. Sed sed felis vestibulum, tincidunt lacus quis, sagittis urna.</Paragraph>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="6">
						<Card size="xlarge" color="primary">
							<CardSection>
								<Heading size="4" whiteout uppercase>Core Disciplines</Heading>
								<Heading size="2" whiteout>Humanities</Heading>
								<Paragraph whiteout>Pellentesque vestibulum aliquet lectus non hendrerit. Mauris vel leo in erat aliquam malesuada dolor ligula ullamcorper nec imperdiet eu.</Paragraph>
							</CardSection>
						</Card>
					</GridContainer>
				</Grid>
			</Section>
			<Section size="large" image="theme-dl/facepaint.jpg">
				<Heading size="4" whiteout uppercase>Dedicated Studio Managers</Heading>
				<Heading size="2" whiteout>Young Artist Studio Program</Heading>				
				<Paragraph whiteout>Aliquam erat volutpat. Donec non odio rhoncus, volutpat lacus vel, eleifend lorem. Praesent suscipit, enim vel vulputate laoreet, nisi enim ultricies metus, eget ultrices velit sem ac erat. Quisque consequat libero id maximus sollicitudin.</Paragraph>
				<Button label="read more" color="dark" />
			</Section>
			<Section size="xsmall" color="primary" full>
				<Grid>
					<GridContainer size="3">
						<Images width="100%" height="375px" image="theme-dl/drip.jpg" />
						<Images width="100%" height="375px" image="theme-dl/woman.jpg" />
					</GridContainer>
					<GridContainer size="3">
						<Stack width="100%" direction="column" space="2" align="center" justify="center" height="375px">
							<Heading size="2" whiteout>What We Do</Heading>
							<Button label="read more" color="secondary" />
						</Stack>
						<Images width="100%" height="375px" image="theme-dl/architecture.jpg" />
					</GridContainer>
					<GridContainer size="6">
						<Images width="100%" height="750px" image="theme-dl/djwoman.jpg" />
					</GridContainer>
				</Grid>
			</Section>
			<Section size="xsmall" color="tertiary" full>
				<Grid>
					<GridContainer size="4">
						<Card size="xlarge" color="tertiary">
							<CardSection>
								<Heading size="4" whiteout uppercase>Undergraduate</Heading>
								<Heading size="2" whiteout>Studios  &amp; Shops</Heading>
								<Paragraph whiteout>Suspendisse potenti. Mauris quis placerat neque. Pellentesque a urna euismod, mattis elit ac, laoreet tellus. Sed convallis est nunc, in semper odio porttitor sed. Nullam lobortis turpis quis erat accumsan, ac pretium augue porttitor.</Paragraph>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="8">
						<Images width="100%" height="100%" image="theme-dl/paint.jpg" />
					</GridContainer>
				</Grid>
			</Section>
			<Section size="xsmall" full>
				<Grid>
					<GridContainer size="6">
						<Card size="xlarge" image="theme-dl/ballerina.jpg">
							<CardSection>
								<Heading size="4" whiteout uppercase>Get in Touch</Heading>
								<Heading size="2" whiteout>Contact Us</Heading>
								<Paragraph whiteout>Dallas School of Art &amp; Design <br/> 1234 Street Rd. <br/> Dallas, TX 12345 <br/> 1 (234) 567 - 7890</Paragraph>
								<Stack justify="left" width="100%" space="1">
									<Icon icon="thumb_up" size="medium" color="light" border />
									<Icon icon="favorite_outline" size="medium" color="light" border />
									<Icon icon="email" size="medium" color="light" border />
								</Stack>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="6">
						<Images width="100%" height="466px" image="theme-dl/art.jpg" />
					</GridContainer>
				</Grid>
			</Section>
		</Body>
		<Footer>
			<Section size="small" color="primary">
				<Stack justify="center">
					<Paragraph micro align="center">© 2022 Dallas is proudly powered by Anna Robbins.</Paragraph>
				</Stack>
			</Section>
		</Footer>
	</Page>
</template>

<script>
export default {
	props: {
		theme: String
	},
  data() {
    return {

		}
  },
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
	methods: {

	}
}

</script>

<style lang="scss">
.logo{
	background: #ef106e;
	padding: 0 5px;
	.heading-02{
		margin: 0;
	}
}
</style>
